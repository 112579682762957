<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-apps mx-8" v-if="!isMobile">
      <v-row class="mt-10">
        <v-col class="text-left " cols="6">
          <h1 class="apps__title">Aplicativos</h1>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="10">
          <v-tabs v-model="tab" background-color="#00000000" color="#466BE3" fixed-tabs show-arrows
            prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline">
            <v-tabs-slider></v-tabs-slider>
            <v-tab @click="all()" href="#apps_tabs_01">
              <img class="mr-2 mt-0" width="15" :src="origin + '/icon/more-Desktop.svg'" />
              Todos
            </v-tab>
            <v-tab @click="favorites()" href="#apps_tabs_02">
              <v-icon small class="mt-0"> mdi-heart-outline </v-icon> &nbsp;
              Favoritos
            </v-tab>
            <v-tab @click="
              otherGroup(data.apps.length > 0 ? data.groupNameCapi : '', i)
              " v-for="(data, i) in listApps" :key="data.id" :href="'#apps_tabs_' + i">
              <v-icon small> mdi-checkbox-blank-circle-outline </v-icon> &nbsp;
              {{ data.apps.length > 0 ? data.groupNameCapi : "" }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="search" label="Buscar" class="mt-0">
            <template v-slot:prepend-inner>
              <v-icon color="#afb0b0"> mdi-magnify </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <p class="apps__subtitle text-left text-capitalize">
            {{ titleSection }}
          </p>
          <v-alert v-if="items.length == 0" dense text type="info" class="mt-3">
            No se encontraron aplicativos en "
            {{ titleSection.toLowerCase() }} ".
          </v-alert>

          <v-data-iterator :items-per-page="-1" :items="items" :search="search" hide-default-footer>
            <template v-slot:no-results>
              <v-alert dense text type="info" class="mt-3">
                No se encontraron aplicativos en "
                {{ titleSection.toLowerCase() }} ".
              </v-alert>
            </template>
            <template class="pl-0 pr-0" v-slot:no-data>
              <p>.</p>
            </template>

            <template v-slot:default="props">
              <v-row>
                <template v-for="(item, i) in props.items">
                  <v-col :key="item.nombre_app" cols="3">
                    <v-card outlined class="apps__card px-3 apps__hover-action" elevation="0" height="250">
                      <div style="width: 100%; text-align: right">
                        <v-btn @click="addFavorite(item, i)" small fab icon v-if="item.favorite == ''">
                          <v-icon color="gray"> mdi-heart-outline </v-icon>
                        </v-btn>
                        <v-btn @click="addFavorite(item, i)" small fab icon v-else>
                          <v-icon color="red"> mdi-heart </v-icon>
                        </v-btn>
                      </div>
                      <div @click="irApp(item)" style="cursor: pointer; height: 190px">
                        <v-btn x-large class="mb-0 mt-0" :class="i == 0 ? 'v-step-12' : ''" icon fab>
                          <img width="60" :src="item.logo" />
                        </v-btn>

                        <p class="mt-2 pb-0 px-3 mb-2 apps__title_app text-capitalize">
                          {{ item.nombre_app }}
                        </p>
                        <p class="mt-0 px-3 apps__txt_app text-3-lines" v-html="item.descripcion">
                          
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="mb-sm-apps ml-3 mr-4" v-else>
      <v-row class="mt-5 apps__padding">
        <v-col cols="12">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label style="padding-top: 30%" class="text-apps-blue-sm txt-capitalize">
                {{ name }},
              </label>
              <label style="padding-top: 30%" class="text-apps-blue-sm">
                acá encontrará
              </label>
            </p>
          </div>
          <p class="text-apps-sm mb-1 ml-9 v-step-mobile-6">
            El acceso a sus aplicativos laborales
          </p>
        </v-col>
        <template v-for="data in listApps">
          <v-col :key="data.id" cols="12" class="mt-0 mb-0 pt-0 pb-0 pl-12 pr-4">
            <p class="text-left text-dark-sub-sm ml-0 mx-1 pb-0 mb-0 v-step-mobile-7">
              {{ data.apps.length > 0 ? data.groupNameCapi : "" }}
            </p>
            <v-row class="mx-1">
              <template v-for="(item, i) in data.apps">
                <!-- CAMBIAR AQUÍ HACERLO POR JS -->
                <v-col class="text-center px-1" :key="item.id" cols="4" v-if="item.dispositivos_moviles == 'true'">
                  <v-btn x-large @click="irApp(item)" :class="i == 0 ? 'v-step-12 v-step-mobile-8' : ''" icon fab>
                    <img style="border-radius: 100px" width="50" :src="item.logo" /> </v-btn><br />
                  <p class="mt-0 mb-0 text-1-lines txt-name-app-sm">
                    {{ item.nombre_app }}
                  </p>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </template>
        <template>
          <v-alert v-if="listApps.length === 0" dense text type="info" style="width: 100%" class="my-4 mx-4 text-center">
            No hay aplicaciones disponibles para versión móvil
          </v-alert>
        </template>
      </v-row>
    </div>

    <Alert :open="alert.open" :text="alert.txt" :title="alert.title" :type="alert.type" :redirect="alert.redirect"
      @close="alert.open = false">
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
// import alert from "@/mixins/alert";
import Api from "@/utils/api";
import moment from "moment";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  data() {
    return {
      tab: 0,
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
      mobile: false,
      loadingAll: false,
      info: {},
      origin: window.location.origin,
      id_profile: null,
      infoToken: {
        document: "",
        nickname: ""
      },
      name: "",
      listApps: [],
      listApplications: {
        chats: {
          id: 0,
          groupName: "Chats",
          apps: [],
        },
        appsBrm: {
          id: 1,
          groupName: "Aplicativos BRM",
          apps: [],
        },
        appsGoogle: {
          id: 2,
          groupName: "Aplicativos Google",
          apps: [],
        },
        appsClient: {
          id: 3,
          groupName: "Aplicativos Cliente",
          apps: [],
        },
      },
      token64: null,
      items: [],
      titleSection: "",
      search: "",
      isMobile: false
    };
  },
  computed: {
    getAllApps() {
      let allApps = this.$store.getters.getApps;
      let user = this.$store.getters.getUser;
      if (allApps.message && user) {
        this.getApps(allApps.data, user);
      } else {
        this.loadingAll = true;
      }
    },
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    addFavorite(item, i) {
      var data = {
        id: item._ID,
        nuevas_cedulas: [this.infoToken.document],
      };
      Api.noAuth()
        .addFavoritesApp(data)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.cod == 0) {
            if (this.items[i].favorite == "red") {
              this.items[i].favorite = "";
              if (this.tab == "apps_tabs_02") {
                this.items.splice(i, 1);
              }
            } else {
              this.items[i].favorite = "red";
            }
            var temp = this.items;
            this.items = [];
            for (let j = 0; j < temp.length; j++) {
              this.items.push(temp[j]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => { });
    },
    all() {
      this.titleSection = "Todos los aplicativos";
      this.items = [];
      for (let i = 0; i < this.listApps.length; i++) {
        for (let j = 0; j < this.listApps[i].apps.length; j++) {
          this.items.push(this.listApps[i].apps[j]);
        }
      }
    },
    favorites() {
      this.titleSection = "Favoritos";
      var temp = this.items;
      this.items = [];

      for (let i = 0; i < this.listApps.length; i++) {
        for (let j = 0; j < this.listApps[i].apps.length; j++) {
          if (this.listApps[i].apps[j].favorite == "red") {
            this.items.push(this.listApps[i].apps[j]);
          }
        }
      }
    },

    otherGroup(title, i) {
      this.titleSection = title;
      this.items = [];
      for (let j = 0; j < this.listApps[i].apps.length; j++) {
        this.items.push(this.listApps[i].apps[j]);
      }
    },
    async irApp(item) {
      console.log(item)
      /*
        ESTA PARTE NO ESTA DISPONIBLE PORQUE AUN NO ESTAN LOS TUTORIALES DE CADA APP
        localStorage.setItem("app", JSON.stringify(item));
        this.$router.push({
          name: "appDetails",
          params: { id: item._ID },
        });
      */
      // ESTA PARTE SE USA TEMPORALMENTE PARA NO REDIRIGIR AL INTERNO DE CADA APP
      if (item.url && item.url !== "#") {
        const user = await sessionStorage.getItem("user_id");
        let dispositivo;
        if (screen.width < 1024) {
          dispositivo = "Mobile";
        } else {
          dispositivo = "Desktop";
        }
        const record = {
          userid: this.infoToken.document,
          appid: item._ID,
          appname: item.nombre_app,
          date: moment().format("YYYY-MM-DD"),
          dispositivo,
        };
        Api.noAuth()
          .saveAppActivity(record)
          .then((resp) => resp.json())
          .then((data) => {
            if (data.success) {
              console.log("Se registro con exito");
            }
            if (item.url.includes('linguo/marcas')) {
              var url = item.url + "/" + item._ID;
            } else if (item.es_un_iframe == 'true') {
              var url = window.location.href + '/reporting/' + item._ID;
              this.$router.push({ name: 'appReporting', params: { id: item._ID } })
              return
            } else {
              var token = `?token=${this.token64}`;
              var url = item.url.replace("?token=", token);
              url = url.replace("{user}", this.infoToken.nickname);
            }
            let newURL = document.createElement("a");
            newURL.id =
              "id-" + Math.floor(Math.random() * (9999999 - 500) + 500);
            newURL.href = url;
            newURL.target = "_blank";
            document.body.appendChild(newURL);
            newURL.click();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.alert.open = true;
        this.alert.type = "info";
        this.alert.title = "Para que sepas...";
        this.alert.txt = "Próximamente esta aplicación estará disponible";
      }
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));

        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
          nickname: ""
        };
      }
    },
    getGroupData(groupName) {
      var pos = null;
      for (let i = 0; i < this.listApps.length; i++) {
        if (groupName == this.listApps[i].groupName) {
          pos = i;
        }
      }
      return pos;
    },
    getApps(allApps, user) {
      if (allApps && allApps.length !== 0) {
        let end = allApps.length - 1;
        if (this.listApps.length === 0) {
          for (let i = 0; i < allApps.length; i++) {
            allApps[i].favorite = "";
            if (allApps[i].hasOwnProperty("favorita") && allApps[i].favorita) {
              allApps[i].favorite = "red";
            }
            if (allApps[i].es_un_modulo != "true") {
              if (
                
                allApps[i].sede === user.headquarters_city ||
                allApps[i].sede === "Todas las sedes" ||
                !allApps[i].sede
              ) {
                var position = this.getGroupData(allApps[i].tipo);
                if (position || position === 0) {
                  if (this.mobile || screen.width < 1024) {
                    if (allApps[i].dispositivos_moviles == "true") {
                      this.listApps[position].apps.push(allApps[i]);
                    }
                  } else {
                    this.listApps[position].apps.push(allApps[i]);
                  }
                } else {
                  if (this.mobile || screen.width < 1024) {
                    if (allApps[i].dispositivos_moviles == "true") {
                      this.listApps.push({
                        groupNameCapi: allApps[i].tipo.replace("-", " "),
                        groupName: allApps[i].tipo,
                        apps: [allApps[i]],
                      });
                    }
                  } else {
                    this.listApps.push({
                      groupNameCapi: allApps[i].tipo.replace("-", " "),
                      groupName: allApps[i].tipo,
                      apps: [allApps[i]],
                    });
                  }
                }
              }
            }
            if (i == end) {
              this.loadingAll = false;
            }
          }
          this.all();
        }
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    this.getToken();
    this.id_profile = sessionStorage.getItem("id_profile");
  },
  created() {
    this.all();
    this.token64 = localStorage.getItem("tokenB64");
    if (screen.width < 1024) {
      this.mobile = true;
    }    
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  watch: {
    getAllApps: function (newCount, oldCount) {
      this.all();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/main";

.navidad-text{
  display: flex;
}

.mdi-chevron-right-circle-outline::before {
  color: #466be3 !important;
}

.mdi-chevron-left-circle-outline::before {
  color: #466be3 !important;
}

.mb-sm-apps {
  display: none;
}

.apps__card {
  // background-image: url(../../assets/img/navidad/border-navidad7.png);
  // background-repeat: no-repeat;
  //   background-size: contain;
  //   background-position-y: -20px;
  border: 0.5px solid #d5dbff !important;
  border-radius: 15px !important;
}

.apps__hover-action:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.apps__title_app {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #353535;
}

.apps__txt_app {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #626262;
}

.apps__subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 29px;
  color: #000000;
}

.apps__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
  white-space: initial !important;
}

.v-tab {
  text-transform: none !important;
  font-family: "RobotoRegular" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #898989 !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {}

/* LAPTOP LARGE */

@media (max-width: 1600px) {}

/* LAPTOP LARGE */

@media (max-width: 1400px) {}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-apps {
    display: none;
  }

  .mb-sm-apps {
    display: block;
    text-align: initial;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
    // font-family: "proximaNovaRegular";
  }

  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }

  .text-dark-sub-sm {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #466be3;
    // font-family: "proximaNovaRegular";
    text-transform: capitalize;
  }

  .txt-name-app-sm {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #353535;
    font-family: "proximaNovaRegular";
  }

  .apps__padding {
    padding-bottom: 60px;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-apps {
    display: none;
  }

  .mb-sm-apps {
    display: block;
    text-align: initial;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
    // font-family: "proximaNovaRegular";
  }

  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }

  .text-dark-sub-sm {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #466be3;
    // font-family: "proximaNovaRegular";
    text-transform: capitalize;
  }

  .txt-name-app-sm {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #353535;
    font-family: "proximaNovaRegular";
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-apps {
    display: none;
  }

  .mb-sm-apps {
    display: block;
    text-align: initial;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
    // font-family: "proximaNovaRegular";
  }

  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }

  .text-dark-sub-sm {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #466be3;
    // font-family: "proximaNovaRegular";
    text-transform: capitalize;
  }

  .txt-name-app-sm {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #353535;
    font-family: "proximaNovaRegular";
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-apps {
    display: none;
  }

  .mb-sm-apps {
    display: block;
    text-align: initial;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
    // font-family: "proximaNovaRegular";
  }

  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }

  .text-dark-sub-sm {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #466be3;
    // font-family: "proximaNovaRegular";
    text-transform: capitalize;
  }

  .txt-name-app-sm {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #353535;
    font-family: "proximaNovaRegular";
  }
}
</style>
